/*
REFERENCES
https://medium.com/@tacomanator/environments-with-create-react-app-7b645312c09d
https://medium.com/@trekinbami/using-environment-variables-in-react-6b0a99d83cf5
*/

export const config = {
  baseurl: process.env.REACT_APP_BASE_URL || "http://localhost:5001",
  socketUrl: process.env.REACT_APP_SOCKET_URL || "http://localhost:1212",
  mainUrl: process.env.REACT_APP_MAIN_URL || "http://localhost:8000",
  frontendUrl: process.env.REACT_APP_FRONTEND_URL || "http://localhost:3000",
  // stripeKey: "pk_live_McU009dgrnIFrw0lUCt5ZCBa00Fx0dhEHN",
  stripeKey: "ca_FScUEzy133E6nlv73wrBbOImu3tfJnG4",

  liftRocketSupportEmail: "member.services@liftrocket.com",
  defaultProfilePhoto:
    "https://s3.us-east-2.amazonaws.com/liftrocket.org/astroFlag.jpg",
  defaultLiftPhoto:
    "https://s3.us-east-2.amazonaws.com/liftrocket.org/astroFlag.jpg",
  //defaultProfilePhoto: `/logo/Logo_knockout.svg`,
  // defaultLiftPhoto: `/logo/Logo_knockout.svg`,
  changefee: 8,
  liftContributionMax: 1000,
  liftContributionMin: 10,
  minLiftFriends: 0,
  shareLink: "/public/lift",
};
// google event config
export const googleTagEvent = {
  login: {
    dataLayer: {
      event: "Login",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  register: {
    dataLayer: {
      event: "Register",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageOne: {
    dataLayer: {
      event: "Lift_Stage_One",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageTwo: {
    dataLayer: {
      event: "Lift_Stage_Two",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageThree: {
    dataLayer: {
      event: "Lift_Stage_Three",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageFour: {
    dataLayer: {
      event: "Lift_Stage_Four",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageFive: {
    dataLayer: {
      event: "Lift_Stage_Five",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageSix: {
    dataLayer: {
      event: "Lift_Stage_Six",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageSeven: {
    dataLayer: {
      event: "Lift_Stage_Seven",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageEight: {
    dataLayer: {
      event: "Lift_Stage_Eight",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  liftStageNine: {
    dataLayer: {
      event: "Lift_Stage_Nine",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  boosterStageOne: {
    dataLayer: {
      event: "Booster_Stage_One",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  boosterStageTwo: {
    dataLayer: {
      event: "Booster_Stage_Two",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  boosterStageThree: {
    dataLayer: {
      event: "Booster_Stage_Three",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  updateUserInfo: {
    dataLayer: {
      event: "Update_User_Info",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  inviteFriend: {
    dataLayer: {
      event: "Invite_Friend",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },

  inviteFriendForLift: {
    dataLayer: {
      event: "Invite_Friend_for_Lift",
      eventErrorLine: "success",
      eventErrorLineType: "string",
    },
    dataLayerName: "PageDataLayer",
  },
};




import React, { useRef } from "react";
// import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "components/shared";
import { Admin } from "components/admin";
import {
  TermsofUseAgreement,
  Display,
  NotFound,
  PrivacyPolicyAgreement,
  FaqIFrame,
} from "components/info";
// import { RequestLift, RegisterNewBooster } from "components/lift";
// import { Dashboard } from "components/liftDashboard";
import { Message } from "components/message";
import {
  Verify,
  VerifywToken,
  Login,
  Logout,
  // Register,
  Checkout,
  CheckoutPageCredit,
} from "components/session";
import ForgotPassword from "components/session/forgot-password";
import ResetPassword from "components/session/reset-password";
import { SettingProfile, VerifyPhoneNumber } from "components/user";
import { UpdateStripeInfo } from "components/user/update";
// import { LiftDashBoard } from "components/liftDashboard";
import ScrollToTop from "components/shared/scroll-to-top";

import IdleTimer from "react-idle-timer";
import { useUserContext } from "contexts";
import { apiRoutes } from "model";
import { httpGet } from "utilities";
import { config } from "model";

const SessionTimeout = () => {
  const idleTimer = useRef(null) as any;
  const [user, refresh] = useUserContext();
  // const history = useHistory();

  // const handleTabClose = () => {
  //   if (document.visibilityState === "hidden") {
  //     clear();
  //   }
  // };
  // const clear = () => {
  //   if (localStorage.getItem("logout") === "true") {
  //     localStorage.clear();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("visibilitychange", handleTabClose);
  //   window.addEventListener("pagehide", clear);
  //   window.addEventListener("focus", clear);
  //   window.addEventListener("blur", clear);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleTabClose);
  //     window.removeEventListener("pagehide", clear);
  //     window.removeEventListener("focus", clear);
  //     window.removeEventListener("blur", clear);
  //   };
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onActive = () => {};

  const onIdle = () => {
    if (user && user.status === 0) {
      httpGet<any>(apiRoutes.logout).then((res: any) => {
        localStorage.clear();
        refresh();
        const msg =
          "We noticed you stepped away from your computer so we logged you out to protect your account.  Please log in to continue.";
        window.location.href = config.frontendUrl + `/login?notice=${msg}`;
      });
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={5 * 60 * 1000}
      />
    </>
  );
};

const Routes = () => (
  <div className="liftRocketBackground">
    <Router>
      <ScrollToTop>
        <SessionTimeout />
        <Switch>
          {/* <ProtectedRoute path="/admin" component={Admin} /> */}
          {/* User */}
          {/* <ProtectedRoute path="/profileSetting" component={SettingProfile} />
          <ProtectedRoute path="/messages" component={Message} /> */}
          {/* <ProtectedRoute path="/liftDashboard" component={LiftDashBoard} /> */}
          {/* <Route path="/stripeInfo" component={UpdateStripeInfo} /> */}

          {/* General */}
          {/* <Route path="/verify" component={Verify} />
          <ProtectedRoute path="/verifywtoken" component={VerifywToken} />
          <ProtectedRoute path="/verifyPhone" component={VerifyPhoneNumber} />
          <Route exact path={["/login", "/"]} component={Login} />
          <Route exact path="/logout" component={Logout} /> */}
          {/* <Route exact path="/register" component={Register} /> */}
          {/* <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/passwordReset" component={ResetPassword} />
          <Route path="/faqIFrame" component={FaqIFrame} /> */}

          {/* Lift */}
          {/* <Route path="/requestLift" component={RequestLift} />
          <Route path="/newBooster" component={RegisterNewBooster} /> */}

          {/* Info */}
          {/* <Route exact path="/display/:id?" component={Display} />
          <Route
            exact
            path="/privacypolicy"
            component={PrivacyPolicyAgreement}
          />
          <Route exact path="/termsofuse" component={TermsofUseAgreement} /> */}

          {/* public */}
          {/* <Route path="/stripe/checkout" component={StripeCardCheckout} /> */}

          {/* Banking */}
          {/* <ProtectedRoute path="/checkout" component={Checkout} />
          <ProtectedRoute path="/creditcard" component={CheckoutPageCredit} /> */}

          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  </div>
);

export default Routes;

import React from "react";
import logoStandard from "assets/svg/Logo_standard.svg";
import facebook from "assets/svg/facebook-footer.svg";
import twitter from "assets/svg/twitter-footer.svg";
import instagram from "assets/svg/instagram-footer.svg";
import { config } from "model";

export const Footer = () => {
  return (
    <>
      <div className="has-background-white">
        <div className="liftRocketBody">
          <div className="footer footerFont">
            <div className="columns">
              <div className="column is-one-quarter">
                <div className="">
                  <div className="footerImageContainer">
                    <a href={config.mainUrl} className="column footerItemImage">
                      <img
                        src={logoStandard}
                        className="footerLogo"
                        alt="header rocket"
                      />
                    </a>
                    <div className="footerSocialContainer">
                      <a
                        href="https://www.facebook.com/liftrockett/"
                        className="footerSocial"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                      <a
                        href="https://twitter.com/liftrocket?lang=en"
                        className="footerSocial"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                      <a
                        href="https://www.instagram.com/liftrocket/"
                        className="footerSocial"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-three-quarter">
                <div className="footerContent">
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <a href={`${config.mainUrl}/ourstory`}>ABOUT US</a>
                      <a href={`${config.mainUrl}/faq`}>FAQS</a>
                      <a href={`${config.mainUrl}/blog`}>BLOG</a>
                    </div>
                  </div>
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <div className="">QUESTIONS?</div>
                      <a href="mailto:member.services@liftrocket.com">
                        CONTACT US
                      </a>
                      <a href={`/requestLift/1`}>REQUEST LIFT</a>
                    </div>
                  </div>
                  <div className="footerContentItem mobileFooterMT">
                    <div className="flexCol">
                      <a href={`/termsofuse`}>TERMS {"&"} CONDITIONS</a>
                      <a href={`/privacypolicy`}>PRIVACY POLICY</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

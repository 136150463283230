import React from "react";
import Routes from "routes";
import { ThemeProvider } from "styled-components";
import { theme } from "components/styled";
import { GlobalProvider } from "contexts";
import "assets/styles/index.scss";
import TagManager from "react-gtm-module";

const googleManagerConfig = process.env.REACT_APP_GOOGLETAGMANAGER || "no";
if (googleManagerConfig === "yes") {
  const tagManagerArgs = {
    gtmId: "GTM-KCCFNTQ",
    dataLayerName: "PageDataLayer",
  };
  TagManager.initialize(tagManagerArgs);
}

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  </ThemeProvider>
);

export default App;

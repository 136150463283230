import React, { useReducer, useContext } from "react";

const liftReviewState = {
  data: null,
  recPayment: null,
  liftHealthReport: null,
  checkList:null,
};

const LiftReviewContext = React.createContext<[Data, Dispatch] | undefined>(
  undefined
);
type Data = { data: any, recPayment:any, liftHealthReport :any, checkList:any};
type Action = { type: "setData"; payload: { data: any } } |{ type: "setRecPayment"; payload: { data: any } } | { type: "setLiftHealthReport"; payload: { data: any } }  | { type: "setCheckList"; payload: { data: any } }| { type: "reset" };

type Dispatch = (action: Action) => void;

const LiftReviewReducer = (state: Data, action: Action) => {
  switch (action.type) {
    case "setData":
      return { ...state, data: action.payload.data };
    case "setCheckList":
      return { ...state, checkList: action.payload.data };
    case "setRecPayment":
      return { ...state, recPayment: action.payload.data };
    case "setLiftHealthReport":
      return { ...state, liftHealthReport: action.payload.data };
    case "reset":
      return { ...liftReviewState };
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const LiftReviewProvider: React.FC = ({ children }) => {
  const [location, dispatch] = useReducer(LiftReviewReducer, liftReviewState);
  return (
    <LiftReviewContext.Provider value={[location, dispatch]}>
      {children}
    </LiftReviewContext.Provider>
  );
};

export const useLiftReviewReducer = () => {
  const context = useContext(LiftReviewContext);
  if (context === undefined) throw new Error("location context is undefined");
  return context;
};

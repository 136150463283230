export const apiRoutes = {
  register: "/api/v1/register",
  verifyPhoneNumber: "/api/v1/register/verifyPhoneNumber",
  checkVerifyPhoneNumber: "/api/v1/register/checkVerifyPhoneNumber",
  registerEmail: "/api/v1/register/registerEmail",
  registerBoosterEmail: "/api/v1/register/registerBoosterEmail",
  registercheckEmailInUse: "/api/v1/register/checkEmailInUse",
  addBoostertoLift: "/api/v1/register/addBoostertoLift",
  verifyEmail: "/api/v1/register/sendVerifyEmail",
  login: "/api/v1/user/action/login",
  logout: "/api/v1/user/action/logout",

  changePassword: "/api/v1/user/action/changePassword",
  changePasswordNoToken: "/api/v1/user/action/changePasswordNoToken",
  resetPassword: "/api/v1/user/action/resetPassword",
  forgotPassword: "/api/v1/user/action/forgotPassword",
  updateInfo: "/api/v1/user/action/updateProfile",
  verify: "/api/v1/user/action/verify",
  profiler: "/api/v1/user/info/profiler",
  userContext: "/api/v1/user/info/userContext",

  sent: "/api/v1/messages/info/sent",
  inbox: "/api/v1/messages/info/received",
  receivedWThreads: "/api/v1/messages/info/receivedWThreads",
  sendMessage: "/api/v1/messages/action/send",
  changeMessageStatus: "/api/v1/messages/action/statusChange",
  inviteBooster: "/api/v1/user/action/social/inviteBoosterList",

  current: "/api/v1/lift/info/currentLift",
  boosterCurrentLift: "/api/v1/lift/info/boostersCurrentLift",
  liftHistory: "/api/v1/lift/info/liftHistory",
  contribute: "/api/v1/lift/action/contribute",
  publicContribute: "/api/v1/lift/action/publicContribute",
  checkEligible: "/api/v1/lift/info/checkEligible",

  requestLiftInfo: "/api/v1/lift/info/getInfo",
  getLiftTermsNew: "/api/v1/lift/info/newterms",
  getBoosterLinks: "/api/v1/lift/info/booster",
  smsBoosterLinks: "/api/v1/lift/info/smsBoosterCode",
  requestLift: "/api/v1/lift/action/request",

  newRequestActivate: "/api/v1/lift/action/activate",
  newBoosterActivate: "/api/v1/lift/action/activateBooster",
  addBooster: "/api/v1/register/addBooster",

  updateLift: "/api/v1/lift/action/update",
  cancelLift: "/api/v1/lift/action/cancel",
  makePayment: "/api/v1/lift/action/payback",

  checkUserVerify: "/api/v1/user/info/check/verify",
  checkFees: "/api/v1/transmitter/stripe/paymentGross",
  withdrawableCash: "/api/v1/user/info/financial/withdrawableCash",
  withdrawCash: "/api/v1/user/action/financial/pickup/liftFunds",
  emaillSubscription: "/api/v1/register/emailSubscription",

  plaidInitialization: "/api/v1/plaid/initialization",
  plaidAuthorization: "/api/v1/plaid/authorization",
  plaidUpdate: "/api/v1/plaid/authorization/update",
  plaidCheck: "/api/v1/plaid/check",
  plaidLinkInitialization: "/api/v1/plaid/link/initialization",
  plaidLinkAuthorization: "/api/v1/plaid/link/authorization",

  stripeCheckout: "/api/v1/lift/action/checkout",
  stripeVerify: "/api/v1/transmitter/stripe/connect/verification",
  stripeCheck: "/api/v1/transmitter/stripe/connect/check",
  stripeLink: "/api/v1/transmitter/stripe/link",
  stripeStatus: "/api/v1/user/info/stripeStatus",
  stripeCredentials: "/api/v1/user/info/stripeCredentials",
  stripeInfoUpdate: "/api/v1/user/action/updateStripe",

  emailUnsubscribe: "/api/v1/user/action/email/unsubscribed",
  emailSubscribe: "/api/v1/user/action/email/subscribed",
  track: "/api/v1/staticinfo/track",
  trackData: "/api/v1/staticinfo/track/data",

  reviewLift: "/api/v1/admin_route/reviewLift",
  undoLift: "/api/v1/admin_route/undoReviewLiftDecision",
  recPaymentUpdate: "/api/v1/admin_route/recommendedPayment",
  liftHealthReport: "/api/v1/admin_route/lift/health/report"
};

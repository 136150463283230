import React, { useReducer, useContext } from "react";

const initialLiftDashboardBoosterState = {
  response: {
    status: -1,
    statusText: "",
    liftStatus: -1,
    liftAmount: 0,
    totalBalance: 0,
    totalBalanceWithFee: 0,
    biWeeklyPayment: 0,
    numberOfPayments: 0,
    boosterCode: "",
    timeToEnrollBooster: 0,
    firstName: "",
    lastName: "",
    communityContribution: 0,
    principalRepayment: 0,
    boosters: [],
    numberPaymentTillLiftIncrease: 0,
    nextPaymentDueDate: 0,
    numberPaymentsToGo: 0,
    numberPaymentsMade: 0,
    liftId: "",
    fractionPayedOff: 0,
    maxBoosterCountReached: false,
    paymentsByLiftOwner: 0,
    paymentsByBooster: 0,
    bannerMessage: "",
    boosterReleaseDate: 0,
    latePayment: false,
  },
};

const LiftDashboardBoosterContext = React.createContext<
  [Data, Dispatch] | undefined
>(undefined);
type Data = {
  response: {
    status: number;
    statusText: string;
    liftStatus: number;
    liftAmount: number;
    totalBalance: number;
    totalBalanceWithFee: number;
    biWeeklyPayment: number;
    communityContribution: number;
    principalRepayment: number;
    numberOfPayments: number;
    boosterCode: string;
    timeToEnrollBooster: number;
    firstName: string;
    lastName: string;
    boosters: Array<{
      firstName: string;
      lastName: string;
    }>;
    numberPaymentTillLiftIncrease: number;
    nextPaymentDueDate: number;
    numberPaymentsToGo: number;
    numberPaymentsMade: number;
    liftId: string;
    fractionPayedOff: number;
    maxBoosterCountReached: boolean;
    paymentsByLiftOwner: number;
    paymentsByBooster: number;
    bannerMessage: string;
    boosterReleaseDate: number;
    latePayment: boolean;
  };
};

type Action =
  | { type: "setDashboardBooster"; payload: { data: any } }
  | { type: "reset" };

type Dispatch = (action: Action) => void;

const LiftDashboardBoosterReducer = (state: Data, action: Action) => {
  switch (action.type) {
    case "setDashboardBooster":
      return { ...state, response: action.payload.data };
    case "reset":
      return { ...initialLiftDashboardBoosterState };
    default:
      throw new Error(`unknown action ${action}`);
  }
};

export const LiftDashboardBoosterProvider: React.FC = ({ children }) => {
  const [location, dispatch] = useReducer(
    LiftDashboardBoosterReducer,
    initialLiftDashboardBoosterState
  );
  return (
    <LiftDashboardBoosterContext.Provider value={[location, dispatch]}>
      {children}
    </LiftDashboardBoosterContext.Provider>
  );
};

export const useLiftDashboardBoosterReducer = () => {
  const context = useContext(LiftDashboardBoosterContext);
  if (context === undefined)
    throw new Error("lift dashboard context is undefined");
  return context;
};

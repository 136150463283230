import React from "react";
import { Link } from "react-router-dom";
import { scaleDown as Menu } from "react-burger-menu";
// import { config } from "model";

type mobileNavbarProps = {
  dark?: boolean;
  auth?: boolean;
  absolute?: boolean;
  admin: boolean;
};

export const MobileNavbar = ({
  dark,
  auth,
  absolute,
  admin,
}: mobileNavbarProps) => {
  const pubUrl = process.env.PUBLIC_URL;

  return (
    <>
      <Link to="/liftDashboard">
        <img
          src={`/icons/rocket_${dark ? "blue" : "white"}.svg`}
          style={{ width: 50, position: "absolute", right: 30, top: 30 }}
          alt="header"
        />
      </Link>
      <div
        className="burger-menu-wrapper"
        style={{ position: absolute ? "absolute" : "inherit" }}
      >
        <Menu
          disableAutoFocus
          customBurgerIcon={
            <img
              src={`${pubUrl}/svg/menu_${dark ? "blue" : "white"}.svg`}
              alt="="
            />
          }
          customCrossIcon={<img src={`${pubUrl}/svg/x.svg`} alt="X" />}
        >
          <Link
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            to={auth ? "/liftDashboard" : "/"}
          >
            <img
              style={{ width: "60%", margin: "0px" }}
              src={`${pubUrl}/svg/Logo_knockout.svg`}
              alt="header rocket"
            />
          </Link>
          <hr />
          {auth ? (
            <>
              {admin && <MobileNavLink to="/admin" text="ADMIN" />}
              <MobileNavLink to="/messages/inbox" text="INBOX" />
              <MobileNavLink to="/messages/compose" text="CONTACT US" />
              <MobileNavLink to="/faqIFrame" text="HELP CENTER" />
              <MobileNavLink to="/profileSetting" text="SETTINGS" />
              <MobileNavLink to="/liftDashboard" text="DASHBOARD" />

              <MobileNavLink to="/logout" text="Log out" img="svg/xWhite.svg" />
            </>
          ) : (
            <MobileNavLink
              to="/login"
              text="Log in"
              img="svg/account_white.svg"
            />
          )}
        </Menu>
      </div>
    </>
  );
};

const MobileNavLink = ({
  to,
  text,
  img,
}: {
  to: string;
  text: string;
  img?: string;
}) => (
  <Link className="menu-item button is-primary" to={to}>
    {img && (
      <span className="icon">
        <img
          className="mobileNavIcon"
          src={`${process.env.PUBLIC_URL}/${img}`}
          alt="navicon"
        />
      </span>
    )}
    <span style={{ minWidth: 90 }}>{text}</span>
  </Link>
);

// const ExternalNavLink = ({
//   to,
//   text,
//   img,
// }: {
//   to: string;
//   text: string;
//   img?: string;
// }) => (
//   <a className="menu-item button is-primary" href={to}>
//     {img && (
//       <span className="icon">
//         <img
//           className="mobileNavIcon"
//           src={`${process.env.PUBLIC_URL}/${img}`}
//           alt="navicon"
//         />
//       </span>
//     )}
//     <span style={{ minWidth: 90 }}>{text}</span>
//   </a>
// );

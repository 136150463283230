import React from "react";
import { Link } from "react-router-dom";
// import { config } from "model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "contexts";
type DesktopNavbarProps = {
  dark?: boolean;
  absolute?: boolean;
  auth?: boolean;
  admin?: boolean;
};

export const DesktopNavbar = ({
  dark,
  auth,
  absolute,
  admin,
}: DesktopNavbarProps) => {
  const [user] = useUserContext();

  return (
    <div
      className={`navbar is-primary pb-0 pt-0`}
      role="navigation"
      aria-label="main navigation"
      style={{
        position: absolute ? "absolute" : "inherit",
        background: "none",
        width: "100vw",
      }}
    >
      <div
        className={` container navContainer has-text-${
          dark ? "primary" : "white"
        }`}
      >
        <div className="navbar-brand">
          <Link to={auth ? "/liftDashboard" : "/"}>
            <img
              src={`${process.env.PUBLIC_URL}/svg/Logo_${
                dark ? "standard" : "knockout"
              }.svg`}
              alt="header rocket"
              width="120"
              height="120"
              style={{ padding: 10 }}
            />
          </Link>
        </div>
        <div id="lr-navbar" className="navbar-menu">
          <div
            className={`navbar-end has-button-border-${
              dark ? "primary" : "white"
            }`}
          >
            <div className="buttons">
              {admin && (
                <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <Link to={"/admin"}>
                    <div className="navItemFont">ADMIN</div>
                  </Link>
                </div>
              )}

              {auth && (
                <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <Link to={"/messages/inbox"}>
                    <div className="navItemFont">INBOX</div>
                  </Link>
                </div>
              )}

              {/* {auth && (
                <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <Link to={"/messages/compose"}>
                    <div className="navItemFont">CONTACT US</div>
                  </Link>
                </div>
              )} */}

              {/* <div
                className={`navbar-item is-tab ${dark ? "" : "has-text-white"}`}
              >
                <a href={`${config.mainUrl}/faqIFrame`} className="navItemFont">
                  HELP CENTER
                </a>
              </div> */}

              {/* <Link to={"/faqIFrame"}>
                <div className="navItemFont">HELP CENTER</div>
              </Link> */}

              {auth && (
                <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <Link to={"/profileSetting"}>
                    <div className="navItemFont">SETTINGS</div>
                  </Link>
                </div>
              )}
              {/* <div
                  className={`navbar-item is-tab ${
                    dark ? "" : "has-text-white"
                  }`}
                >
                  <a
                    href={`${config.mainUrl}/blog`}
                    className="navItemFont"
                   
                  >
                    BLOG
                  </a>
                </div> */}

              {auth ? (
                <div className="navMainUserBox">
                  <Link to={"/admin"}>
                    <div className="navUserBox">
                      <div className="navPersonImage">
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          color="#4d6177"
                          className="fa-2x"
                        />
                      </div>

                      <div className="navName">{user && user.firstName}</div>
                    </div>
                  </Link>
                  <Link to={"/logout"}>
                    <div className="navPersonImage">
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        color="#4d6177"
                        className="fa-1x"
                      />
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="navUserBtn">
                  {/* <div className="navbar-item">
                    <NavLinkRed to="/register" text="Sign up" />
                  </div> */}
                  <div className="navbar-item">
                    <NavLinkLightGrey to="/login" text="Sign in" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const NavLink = ({
//   to,
//   text,
//   img,
// }: {
//   to: string;
//   text?: string;
//   img?: string;
// }) => (
//   <Link
//     to={to}
//     className="button is-primary is-rounded"
//     style={{ border: "2px solid", padding: "0px 20px" }}
//   >
//     {img && (
//       <span className="icon">
//         <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
//       </span>
//     )}
//     {text && <span>{text}</span>}
//   </Link>
// );

// const NavLinkRed = ({
//   to,
//   text,
//   img,
// }: {
//   to: string;
//   text?: string;
//   img?: string;
// }) => (
//   <Link
//     to={to}
//     className="button whitetext is-danger is-rounded borderLessBtn"
//     style={{ border: "2px solid", padding: "0px 20px" }}
//   >
//     {img && (
//       <span className="icon">
//         <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
//       </span>
//     )}
//     {text && <span>{text}</span>}
//   </Link>
// );

const NavLinkLightGrey = ({
  to,
  text,
  img,
}: {
  to: string;
  text?: string;
  img?: string;
}) => (
  <Link
    to={to}
    className="button lightgrey is-rounded borderLessBtn"
    style={{ padding: "0px 20px" }}
  >
    {img && (
      <span className="icon">
        <img src={`${process.env.PUBLIC_URL}/${img}`} alt="navicon" />
      </span>
    )}
    {text && <span>{text}</span>}
  </Link>
);

// type HoverProps = {
//   admin?: boolean;
// };
// const Hover = ({ admin }: HoverProps) => (
//   <div
//     className="navbar-item has-dropdown is-hoverable button is-primary is-rounded "
//     style={{ border: "2px solid" }}
//   >
//     <p
//       className="navbar-link is-rounded"
//       style={{ borderRadius: "0px 22px 22px 0px" }}
//     ></p>

//     <div className="navbar-dropdown is-boxed is-right is-active">
//       <a
//         href={`${config.mainUrl}/about`}
//         className="navbar-item nav-text-space"
//       >
//         How It Works
//       </a>
//       <a href={`${config.mainUrl}/faq`} className="navbar-item nav-text-space">
//         FAQS
//       </a>
//       <a href={`${config.mainUrl}/blog`} className="navbar-item nav-text-space">
//         Blog
//       </a>
//       <hr className="navbar-divider" />
//       <Link className="navbar-item" to="/logout">
//         Logout
//       </Link>
//       {admin && (
//         <div>
//           <hr className="navbar-divider" />
//           <Link to="/admin" className="dropdown-item">
//             Admin Tools
//           </Link>
//           {/* <p
//             className="btn-link dropdown-item"
//             onClick={() =>
//               httpGet<API & { link: { url: string } }>(
//                 apiRoutes.stripeLink
//               ).then(r => {
//                 if (r.status === 0) window.location.href = r.link.url;
//               })
//             }
//           >
//             Stripe Link
//           </p> */}
//         </div>
//       )}
//     </div>
//   </div>
// );

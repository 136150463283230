import React from "react";

export const FormField: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left">
    <label className="label labelMargin">{label}</label>
    <div className="control">{children}</div>

    <p className="help is-danger error-msg-padding">{error}</p>
  </div>
);
export const FormFieldSoftError: React.FC<{
  error?: string;
  label?: string;
}> = ({ error, label, children }) => (
  <div className="field is-expanded has-text-left">
    <label className="label labelMargin">{label}</label>
    <div className="control">{children}</div>

    <p className="liftDashSoftError">{error}</p>
  </div>
);
export const FormFieldNoLabel: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left">
    <div className="control">{children}</div>

    <p className="help is-danger error-msg-padding">{error}</p>
  </div>
);
export const FormFieldOrange: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left formFieldOrange">
    <label className="labelOrange">{label}</label>
    <div className="control radioButtonVals">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);
export const FormFieldOrangeForPayment: React.FC<{
  error?: string;
  label?: string;
}> = ({ error, label, children }) => (
  <div className="field is-expanded has-text-left formFieldOrange">
    <label className="makePaymentSelect">{label}</label>
    <div className="control radioButtonVals">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);
export const FormFieldOrangeNoLabel: React.FC<{
  error?: string;
  label?: string;
}> = ({ error, label, children }) => (
  <div className="formRadioOrange">
    <div className="control">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);

export const FormField2: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left">
    <label className="label labelMargin">{label}</label>
    <div className="control">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);

export const FormField3: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="settingRowInfo">
    <label className="settingRowInfoItem">{label}</label>
    <div className="control">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);

export const FormField2Sign: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left">
    <label className="label labelMargin">{label}</label>
    <div className="control has-icons-left">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);

export const FormField2SignWith2Label: React.FC<{
  error?: string;
  label?: string;
  label2?: string;
}> = ({ error, label, label2, children }) => (
  <div className="field is-expanded has-text-left">
    <label className="label labelMargin">{label}</label>
    <label className="label2">{label2}</label>
    <div className="control has-icons-left">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);

export const FormFieldWhite: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left">
    <label className="label c-w">{label}</label>
    <div className="control">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);
export const FormFieldAddons: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-addons">
    <label className="label">{label}</label>
    {children}
    <p className="help is-danger">{error}</p>
  </div>
);

//no bueno yet
export const GroupedFormField: React.FC<{ error?: string; label?: string }> = ({
  error,
  label,
  children,
}) => (
  <div className="field is-expanded has-text-left is-grouped">
    <label className="label">{label}</label>
    <div className="control">{children}</div>
    <p className="help is-danger">{error}</p>
  </div>
);
